import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import { graphql } from 'gatsby';
import Gallery from '@components/Gallery/Gallery';
import Spacer from '@components/Spacer/Spacer';
import * as styles from '@layouts/HomeLayout.module.scss';
export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "media/build-ball/gallery" }
      }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Build Ball`}</h1>
    <p className="lead">
  Portable, glowing ball that gives on-call engineers a heads up when something
  breaks.
    </p>
    <h2>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`24 hour battery life, charges with micro USB`}</li>
      <li parentName="ul">{`Lightweight, weighs ~200 grams`}</li>
    </ul>
    <Gallery altText={'Shots of the build ball around Cadre HQ, New York, NY.'} aspectRatio={1.6} caption={'Shots of the build ball around Cadre HQ, New York, NY.'} items={props.data.allFile.edges.map(edge => edge.node.childImageSharp.gatsbyImageData)} mdxType="Gallery" />
    {
      /* > Build Ball glowing green at Cadre HQ, Puck Building, NYC */
    }
    <h2>{`Parts List`}</h2>
    <ul>
      <li parentName="ul">{`Particle Photon microcontroller`}</li>
      <li parentName="ul">{`Flexible RGB LED strip`}</li>
      <li parentName="ul">{`100mm clear plastic ball; sanded down and sprayed with a matte finish for maximum light diffusion`}</li>
    </ul>
    <h2>{`Firmware`}</h2>
    <ul>
      <li parentName="ul">{`Written in C++`}</li>
      <li parentName="ul">{`On bootup: device establishes a connection to local wifi and enters main loop`}</li>
      <li parentName="ul">{`Loop logic pings Jenkins build system and updates LED lights accordingly`}</li>
    </ul>
    <h2>{`Light sequences`}</h2>
    <p>{`Light sequences were programmed using the DAC (digital to analog converter) to drive the color-changing LEDs.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`mode`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`pattern`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`color`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`nominal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`slowly pulsing sine wave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`green`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sharp pulsing saw wave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`red`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`low battery`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`periodic double blip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`red`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`acquiring wifi`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`even flashing square wave`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`blue`}</td>
        </tr>
      </tbody>
    </table>
    <Spacer mdxType="Spacer" />
    <hr></hr>
    <aside>
      <p>{`Built during Summer 2017 with Rron Rexha while working at `}<a parentName="p" {...{
          "href": "/startup/cadre"
        }}>{`Cadre`}</a>{`.`}</p>
    </aside>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      